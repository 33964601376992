import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useToken } from "components/ui-kit/core/token";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import React, { useMemo } from "react";
import { useAppDispatch } from "store/hooks";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetSelectedProblems } from "store/modules/problems/actions/index";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { resetSelectedSolution } from "store/modules/solutions/actions/index";
import { setProjectSettings } from "store/modules/projects/actions";
import { ProjectSettings } from "store/modules/projects/types";
import { getProjectSettingsLabel } from "../utils/get-workspace-settings-label";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { WorkspaceMode } from "store/modules/workspaces/types";

type TopBarScriptButtonCollapsedProps = {
  workspaceMode: WorkspaceMode;
  projectSettings: ProjectSettings;
  subscriptionActive?: boolean;
  setShowCustomScriptModal: (show: boolean) => void;
  setShowDynamicInterviewResearchGoalModal: (show: boolean) => void;
  setShowPlanUpgradeModal: (show: boolean) => void;
};

export const InterviewModeSelectorCollapsed: React.FC<TopBarScriptButtonCollapsedProps> = ({
  workspaceMode,
  projectSettings,
  subscriptionActive,
  setShowCustomScriptModal,
  setShowDynamicInterviewResearchGoalModal,
  setShowPlanUpgradeModal,
}) => {
  const dispatch = useAppDispatch();
  const token = useToken();
  const extraToken = useExtraToken();

  // FEATURE FLAG
  const dynamicInterviewsEnabled = useFeatureFlagEnabled("dynamic-interviews");

  const projectModeLabel = useMemo(
    () => getProjectSettingsLabel(projectSettings),
    [projectSettings]
  );

  return (
    <Dropdown
      disabled={
        workspaceMode !== WorkspaceMode.Regular && workspaceMode !== WorkspaceMode.AnonymousUser
      }
      placement="bottomRight"
      menu={{
        items: [
          {
            key: ProjectSettings.SolutionFeedbackMode.toString(),
            label: "Solution Feedback",
            style: {
              color: "white",
              backgroundColor:
                projectSettings === ProjectSettings.SolutionFeedbackMode
                  ? token.colorPrimary
                  : extraToken.colorSideBarBg,
            },
            onClick: (e) => {
              e.domEvent.stopPropagation();
              dispatch(setProjectSettings(ProjectSettings.SolutionFeedbackMode));
              dispatch(resetResearchGoal());
              dispatch(resetCustomScript());
            },
          },
          {
            key: ProjectSettings.ProblemExplorationMode.toString(),
            label: "Problem Exploration",
            style: {
              color: "white",
              backgroundColor:
                projectSettings === ProjectSettings.ProblemExplorationMode
                  ? token.colorPrimary
                  : extraToken.colorSideBarBg,
            },
            onClick: (e) => {
              e.domEvent.stopPropagation();
              dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
              dispatch(resetResearchGoal());
              dispatch(resetCustomScript());
            },
          },
          {
            type: "divider", // Must have
            style: {
              backgroundColor: "white",
              marginLeft: "10px", // Add padding to the left
              marginRight: "10px", // Add padding to the right
            },
          },
          {
            key: ProjectSettings.CustomScriptMode.toString(),
            label: "Custom Script",
            style: {
              color: "white",
              backgroundColor:
                projectSettings === ProjectSettings.CustomScriptMode
                  ? token.colorPrimary
                  : extraToken.colorSideBarBg,
            },
            onClick: (e) => {
              e.domEvent.stopPropagation();

              if (
                EnvironmentVariables.PAYWALL_ACTIVATION_ENABLED === "false" ||
                subscriptionActive
              ) {
                dispatch(setProjectSettings(ProjectSettings.CustomScriptMode));
                setShowCustomScriptModal(true);
                dispatch(resetSelectedProblems());
                dispatch(resetSelectedSolution());
              } else {
                setShowPlanUpgradeModal(true);
              }
            },
          },
          {
            key: ProjectSettings.DynamicInterviewMode.toString(),
            label: "Dynamic Interview",
            disabled: !dynamicInterviewsEnabled,
            style: {
              color: "white",
              backgroundColor:
                projectSettings === ProjectSettings.DynamicInterviewMode
                  ? token.colorPrimary
                  : extraToken.colorSideBarBg,
            },
            onClick: (e) => {
              e.domEvent.stopPropagation();
              dispatch(setProjectSettings(ProjectSettings.DynamicInterviewMode));

              setShowDynamicInterviewResearchGoalModal(true);
              dispatch(resetSelectedProblems());
              dispatch(resetSelectedSolution());
            },
          },
        ],
        selectable: true,
        style: { backgroundColor: extraToken.colorSideBarBg },
        defaultSelectedKeys: [ProjectSettings.SolutionFeedbackMode.toString()],
      }}
    >
      <Button value="template" style={{}}>
        <Space>
          {projectModeLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
