import {
  faClone,
  faEllipsis,
  faMagicWandSparkles,
  faQuestionCircle,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAnalytics } from "logic/analytics/analytics";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addAudience,
  deleteAllAudiences,
  deleteAudience,
  inferAudience,
  toggleSelectedAudiences,
  updateAudience,
} from "store/modules/audiences/actions/index";
import { inferProblemsByAudience } from "store/modules/problems/actions/index";
import { LoadingStatus } from "store/types";
import { resetInsightsData } from "store/utils/reset-insights-data";
import { NewWorkspaceBoardSection } from "../shared/section";
import FooterAction from "../shared/section/components/footer-action";
import { WorkspaceMode } from "store/modules/workspaces/types";

export const SYNTHETIC_USERS_SECTION_ID = "synthetic-users-section";

type AudiencesProps = {
  hasFooterActions?: boolean;
};

export const Audiences: React.FC<AudiencesProps> = ({ hasFooterActions = true }) => {
  const dispatch = useAppDispatch();
  const audiences = useAppSelector((state) => state.audiences);
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
  const project = useAppSelector((state) => state.projects.data);
  const projectError = useAppSelector((state) => state.projects.error);
  const analytics = getAnalytics();

  const [showDeleteModal, setShowDeleteModal] = useState<"all" | string | undefined>(undefined);

  const isDisabled = useMemo(() => workspaceMode === WorkspaceMode.SavedStudy, [workspaceMode]);

  const isLoading: LoadingStatus = useMemo(() => {
    if (audiences.error || projectError) return LoadingStatus.none;
    return !audiences.data.audiences ? LoadingStatus.multiple : audiences.loading;
  }, [audiences.data.audiences, audiences.loading, audiences.error, projectError]);

  return (
    <NewWorkspaceBoardSection
      htmlId={SYNTHETIC_USERS_SECTION_ID}
      resourceName="audiences"
      resources={audiences.data.audiences || []}
      selectionMode="multiple"
      selectedIds={audiences.data.selectedAudiencesIds || []}
      maxSelected={2}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      onSelect={(resource) => {
        dispatch(toggleSelectedAudiences(resource.id));
        resetInsightsData();
      }}
      disabled={isDisabled}
      onAdd={(resource) => {
        analytics.track("workspace:audience:add");
        dispatch(
          addAudience({
            projectId: project.project?.id || "",
            description: resource.description,
          })
        );
        resetInsightsData();
      }}
      onUpdate={(resource) => {
        analytics.track("workspace:audience:card:edit");
        dispatch(
          updateAudience({
            projectId: project.project?.id || "",
            audienceId: resource.id,
            description: resource.description,
          })
        );
        resetInsightsData;
      }}
      onRemove={(resource) => {
        dispatch(deleteAudience({ audienceId: resource.id }));
        resetInsightsData();
      }}
      onRemoveAll={() => {
        analytics.track("workspace:audience:card:delete-all");
        dispatch(deleteAllAudiences());
        resetInsightsData;
      }}
      loading={isLoading}
      error={audiences.error || projectError}
      itemFooterActions={(resource) =>
        !hasFooterActions
          ? []
          : [
              <FooterAction
                key={`magic-wand-actions-${resource.id}`}
                triggerIcon={faMagicWandSparkles}
                buttonAction={() => {
                  analytics.track("workspace:audience:card:magic-wand");
                }}
                options={[
                  {
                    key: "infer-synthetic-users",
                    label: "Segment audience",
                    icon: <FontAwesomeIcon icon={faUser} />,
                    onClick: () => {
                      dispatch(inferAudience({ audienceId: resource.id }));
                      resetInsightsData();
                      analytics.track("workspace:audience:card:magic-wand:segment-audience", {
                        synthetic_user_id: resource.id,
                      });
                    },
                  },
                  {
                    key: "infer-problems",
                    label: "Infer problems",
                    icon: <FontAwesomeIcon icon={faQuestionCircle} />,
                    onClick: () => {
                      dispatch(inferProblemsByAudience({ audienceId: resource.id }));
                      resetInsightsData();
                      analytics.track("workspace:audience:card:magic-wand:infer-problems", {
                        synthetic_user_id: resource.id,
                      });
                    },
                  },
                ]}
              />,
              <FooterAction
                key={`options-${resource.id}`}
                triggerIcon={faEllipsis}
                options={[
                  {
                    key: "edit",
                    label: "Duplicate",
                    icon: <FontAwesomeIcon icon={faClone} />,
                    onClick: () => {
                      analytics.track("workspace:audience:card:ellipsis:duplicate", {
                        synthetic_user_id: resource.id,
                      });
                      dispatch(
                        addAudience({
                          projectId: project.project?.id || "",
                          description: resource.description,
                        })
                      );
                      resetInsightsData;
                    },
                  },
                  {
                    key: "delete",
                    label: "Delete",
                    danger: true,
                    icon: <FontAwesomeIcon icon={faTrash} />,
                    onClick: () => {
                      analytics.track("workspace:audience:card:ellipsis:remove", {
                        synthetic_user_id: resource.id,
                      });
                      setShowDeleteModal(resource.id);
                    },
                  },
                ]}
              />,
            ]
      }
    />
  );
};
