import { Button, Input, Modal, Space, Typography } from "antd";
import { RouterLink } from "components/protons/router-link";
import { getAnalytics } from "logic/analytics/analytics";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { useCallback, useMemo, useRef, useState } from "react";
import { CHECK_YOUR_SCRIPT_COVER_ROUTES } from "templates/check-your-script/cover/check-your-script-cover-routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { addCustomScript } from "store/modules/custom-script/actions";
import { useCustomScriptInput } from "logic/hooks/custom-script";

type CustomScriptModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const CustomScriptModal: React.FC<CustomScriptModalProps> = ({ visible, setVisible }) => {
  // dispatch
  const dispatch = useAppDispatch();
  const analytics = getAnalytics();
  // store state
  const subscriptionUsageStats = useAppSelector(
    (state) => state.workspaces.data.workspace?.subscriptionUsageStats
  );

  // custom script input local storage
  const localStorageCustomScriptInput = useCustomScriptInput();

  // local state
  const [value, setValue] = useState(localStorageCustomScriptInput.customScriptInput || "");

  // textarea ref
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Default max questions when Paywall is not active
  const defaultCustomScriptMaxQuestions = 10;
  // customScriptMaxQuestions is 10 when Paywall is not active
  // if Paywall is active, customScriptMaxQuestions is the value of customScriptMaxQuestions from the currentProject
  const customScriptMaxQuestions = useMemo(
    () =>
      EnvironmentVariables.PAYWALL_ACTIVATION_ENABLED === "true"
        ? subscriptionUsageStats?.customScriptMaxQuestions || 0
        : defaultCustomScriptMaxQuestions,
    [subscriptionUsageStats]
  );

  const questionCount = useMemo(() => value?.split("\n").length, [value]);

  const isExceedingMaxQuestions = useMemo(() => {
    return !!questionCount && questionCount > customScriptMaxQuestions;
  }, [customScriptMaxQuestions, questionCount]);

  const handleSave = useCallback(() => {
    if (!value) return;
    dispatch(addCustomScript(value));
    setVisible(false);
    localStorageCustomScriptInput.saveCustomScriptInput(value);
  }, [dispatch, setVisible, value, localStorageCustomScriptInput]);

  const handleCancel = useCallback(() => {
    setValue(localStorageCustomScriptInput.customScriptInput || "");
    setVisible(false);
  }, [setValue, setVisible, localStorageCustomScriptInput]);

  const handleClear = useCallback(() => {
    analytics.track("workspace:study-strategy:custom-script-modal:clear");
    setValue("");
    localStorageCustomScriptInput.removeCustomScriptInput();
  }, [setValue, localStorageCustomScriptInput, analytics]);

  return (
    <Modal
      title={"Use your own interview script"}
      open={visible}
      width={"40%"}
      afterOpenChange={(open) => open && textareaRef.current?.focus()}
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
        analytics.track("workspace:study-strategy:custom-script-modal:close");
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            analytics.track("workspace:study-strategy:custom-script-modal:cancel");
            handleCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={() => {
            analytics.track("workspace:study-strategy:custom-script-modal:save");
            handleSave();
          }}
          disabled={!value?.length || isExceedingMaxQuestions}
        >
          Save
        </Button>,
      ]}
    >
      <Space size="large" align="start">
        <Typography.Paragraph>
          {"One question per line, don't make it too long."}
          <br />
          {`Your current subscription plan allows a maximum of ${customScriptMaxQuestions} questions.`}
        </Typography.Paragraph>
        <Button onClick={handleClear}>Clear</Button>
      </Space>
      <Input.TextArea
        ref={textareaRef}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        className="custom-script-text-area"
        autoSize={{ minRows: 10, maxRows: 20 }}
        placeholder={`First question\nSecond question\nThird question\n...`}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        style={{
          resize: "none",
          width: "100%",
        }}
      />
      <Typography.Paragraph />
      {!isExceedingMaxQuestions ? (
        <Typography.Paragraph strong>
          {`Question ${questionCount} of ${customScriptMaxQuestions}`}
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph type="danger">
          {`You are exceeding the maximum number of questions allowed for your current subscription plan.`}
        </Typography.Paragraph>
      )}
      <Typography.Paragraph>
        {"Want to make sure your questions are neutral? Try our sidekick: "}
        <RouterLink
          href={CHECK_YOUR_SCRIPT_COVER_ROUTES.getHref()}
          target="_blank"
          onClick={() => {
            analytics.track("workspace:study-strategy:custom-interview-modal:check-your-script");
          }}
        >
          <Typography.Text>Check Your Script</Typography.Text>
        </RouterLink>
      </Typography.Paragraph>
    </Modal>
  );
};
