import AntIcon from "@ant-design/icons";
import { faEllipsisVertical, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Modal, Spin, Typography, theme } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import Measure from "react-measure";
import { LoadingStatus } from "store/types";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";
import { DeleteModal } from "../../problems";
import { SectionItem } from "./components/section-item";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type WorkspaceBoardResource = {
  id: string;
  description: string;
  createdAt: string;
};

type WorkspaceBoardSectionProps<R extends WorkspaceBoardResource> = {
  htmlId: string;
  resourceName: string;
  resources: R[];
  selectionMode: "single" | "multiple";
  selectedIds: string[];
  maxSelected?: number;
  loading: LoadingStatus;
  disabled?: boolean;
  error?: TransportFailure;
  showDeleteModal?: string;
  setShowDeleteModal: Dispatch<SetStateAction<string | undefined>>;
  itemFooterActions: (resource: R) => React.ReactNode[];
  onSelect: (resource: R) => void;
  onAdd: (resource: R) => void;
  onUpdate: (resource: R) => void;
  onRemove: (resource: R) => void;
  onRemoveAll: () => void;
};

export const NewWorkspaceBoardSection: React.FC<
  WorkspaceBoardSectionProps<WorkspaceBoardResource>
> = ({
  htmlId,
  resourceName,
  resources,
  selectionMode,
  selectedIds,
  maxSelected,
  loading,
  disabled,
  error,
  showDeleteModal,
  setShowDeleteModal,
  itemFooterActions,
  onSelect,
  onAdd,
  onUpdate,
  onRemove,
  onRemoveAll,
}) => {
  // const analytics = useAnalytics();

  const { token } = theme.useToken();
  const extraToken = useExtraToken();

  const [dataContainerHeight, setDataContainerHeight] = useState<number | undefined>();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isAdding, setIsAdding] = useState(false);

  const getDisabledOptions = useCallback(
    (resource: WorkspaceBoardResource) => {
      const isSelected = selectedIds.includes(resource.id);
      if (isSelected)
        return {
          disabled: false,
          disabledText: "",
        };

      if (maxSelected && selectedIds.length >= maxSelected)
        return {
          disabled: true,
          disabledText: `Can't select more than ${maxSelected} ${resourceName}`,
        };
    },
    [selectedIds, maxSelected, resourceName]
  );

  return (
    <>
      <Modal
        title={`Delete all ${resourceName}?`}
        open={showDeleteModal === "all"}
        okText="Delete All"
        okButtonProps={{ danger: true }}
        onOk={() => {
          setShowDeleteModal(undefined);
          onRemoveAll();
        }}
        onCancel={() => setShowDeleteModal(undefined)}
      >
        <p>All information will be lost</p>
      </Modal>

      <Measure
        bounds
        onResize={(contentRect) => {
          setDataContainerHeight(contentRect.bounds?.height);
        }}
      >
        {({ measureRef }) => (
          <div
            id={htmlId}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${token.colorBorder}`,
              borderRadius: `${token.borderRadius}px`,
              overflow: "hidden",
            }}
          >
            {/* SECTION HEADER */}
            <div
              style={{
                padding: token.paddingSM,
                backgroundColor: disabled
                  ? token.colorFillTertiary
                  : extraToken.colorPrimaryBgSecondary,
                borderBottom: `1px solid ${token.colorBorder}`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Title
                type={disabled ? "secondary" : undefined}
                style={{ margin: 0 }}
                level={5}
              >
                {capitalizeFirstLetter(resourceName)}
              </Typography.Title>
              <SignupRequiredTrigger>
                <Dropdown
                  disabled={disabled}
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "delete-all",
                        label: "Delete All",
                        icon: <AntIcon component={() => <FontAwesomeIcon icon={faTrash} />} />,
                        disabled: !resources,
                        danger: true,
                        onClick: () => {
                          setShowDeleteModal(DeleteModal.all);
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    disabled={disabled}
                    style={{ backgroundColor: "transparent", borderColor: "transparent" }}
                    icon={
                      <AntIcon component={() => <FontAwesomeIcon icon={faEllipsisVertical} />} />
                    }
                  />
                </Dropdown>
              </SignupRequiredTrigger>
            </div>

            {/* SECTION BODY */}
            <div
              ref={measureRef}
              style={{
                background: disabled ? "transparent" : token.colorBgBase,
                flex: "1 1 auto",
              }}
            >
              <Spin spinning={loading === LoadingStatus.multiple}>
                {dataContainerHeight && (
                  <div
                    ref={containerRef}
                    style={{
                      overflow: "auto",
                      scrollBehavior: "smooth",
                      height: dataContainerHeight ? `${dataContainerHeight}px` : undefined,
                      maxHeight: dataContainerHeight ? `${dataContainerHeight}px` : undefined,
                      padding: token.padding,
                    }}
                  >
                    {/* Empty State */}
                    {!resources.length && !loading && (
                      <Typography.Paragraph type="secondary">
                        {/* TODO: review */}
                        Add your first {resourceName.toLowerCase()} by clicking the {'"'}
                        Add
                        {'"'} button below.
                      </Typography.Paragraph>
                    )}

                    {/* Resources List */}
                    {resources.map((resource) => (
                      <SectionItem
                        key={resource.id}
                        resource={resource}
                        resourceName={resourceName}
                        resourceLoading={loading === LoadingStatus.single}
                        selected={selectedIds.includes(resource.id)}
                        disabled={disabled || getDisabledOptions(resource)?.disabled}
                        disabledText={getDisabledOptions(resource)?.disabledText}
                        isAdding={false}
                        containerRef={containerRef}
                        role={selectionMode === "single" ? "radio" : "checkbox"}
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        onSelect={() => onSelect(resource)}
                        onRemove={() => onRemove(resource)}
                        onSubmit={(value) => onUpdate({ ...resource, description: value })}
                        footerActions={itemFooterActions(resource)}
                      />
                    ))}

                    {/* Add New Resource */}
                    {isAdding && (
                      <SectionItem
                        containerRef={containerRef}
                        isAdding={true}
                        setIsAdding={setIsAdding}
                        resourceName={resourceName}
                        resourceLoading={loading === LoadingStatus.single}
                        onSubmit={(value) =>
                          onAdd({ id: "", description: value } as WorkspaceBoardResource)
                        }
                      />
                    )}

                    {/* Error State */}
                    {error && <ErrorFeedback error={error} />}

                    {/* Add New Resource Button */}
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        width: "100%",
                      }}
                    >
                      <SignupRequiredTrigger>
                        <Button
                          icon={<AntIcon component={() => <FontAwesomeIcon icon={faPlus} />} />}
                          type="primary"
                          disabled={disabled}
                          onClick={() => setIsAdding(true)}
                        >
                          Add
                        </Button>
                      </SignupRequiredTrigger>
                    </div>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        )}
      </Measure>
    </>
  );
};
