import { Image, Radio, Tooltip } from "antd";
import React from "react";

type InterviewModeButtonProps = {
  onClick: () => void;
  name: string;
  description: string;
  image: string;
  value: string;
  active?: boolean;
  disabled?: boolean;
  tooltipText?: string;
};

export const InterviewModeButton: React.FC<InterviewModeButtonProps> = ({
  onClick,
  name,
  description,
  image,
  value,
  active,
  disabled,
  tooltipText,
}) => {
  return (
    <Radio.Button
      value={value}
      onClick={onClick}
      disabled={disabled ?? false}
      style={{
        borderRadius: "6px",
        maxWidth: "180px",
        height: "58px",
        overflow: "hidden",
        flexShrink: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "7px 11px 11px",
        boxSizing: "border-box",
        border: "none",
        marginLeft: "6px",
        marginRight: "6px",
        position: "relative",
        backgroundColor: active ? "#D0D0FE" : "#f6f6ff",
      }}
    >
      <Tooltip title={tooltipText ? tooltipText : ""}>
        <div
          style={{
            alignSelf: "self-start",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <strong
            style={{
              color: "black",
              flex: "1",
              position: "relative",
              lineHeight: "20px",
              marginLeft: 0,
              whiteSpace: "normal",
              textAlign: "left",
            }}
          >
            {name ?? "NAME"}
          </strong>
        </div>

        <div
          style={{
            alignSelf: "self-start",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "16px",
            fontSize: "12px",
            color: "rgba(0, 0, 0, 0.45)",
          }}
        >
          <div style={{ flex: "1", position: "relative", lineHeight: "20px" }}>
            {description ?? "DESCRIPTION"}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            maxWidth: "20px",
            maxHeight: "20px",
          }}
        >
          <Image preview={false} src={image} alt="File Outline" />
        </div>
      </Tooltip>
    </Radio.Button>
  );
};
