import { Button, Input, Space, Tooltip } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { getAllSelected } from "store/selectors/all-selected";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";

type GenerateSUButtonCollapsedProps = {
  onSubmit: () => void;
  syntheticUsersNumber: number;
  setSyntheticUsersNumber: (number: number) => void;
  workspaceMode: WorkspaceMode;
};

export const GenerateSUButtonCollapsed: React.FC<GenerateSUButtonCollapsedProps> = ({
  onSubmit,
  syntheticUsersNumber,
  setSyntheticUsersNumber,
  workspaceMode,
}) => {
  const allSelected = useAppSelector((state) => getAllSelected(state));
  const analytics = getAnalytics();

  const disabled = useMemo(
    () =>
      !allSelected ||
      workspaceMode === WorkspaceMode.SharedUrl ||
      workspaceMode === WorkspaceMode.SavedStudy,
    [allSelected, workspaceMode]
  );

  const handleClick = useDebouncedCallback(() => {
    onSubmit();
  });

  return (
    <Space direction="horizontal" style={{ paddingBottom: "12px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          paddingLeft: "16px",
          height: "58px",
          width: "240px",
          color: "#fff",
          backgroundColor: "#434343",
          borderRadius: "6px",
        }}
      >
        <div style={{ color: "white", maxWidth: "80px", marginRight: "8px" }}>
          {" "}
          Number of Interviews
        </div>
        <Space direction="horizontal" style={{ paddingRight: "8px" }}>
          <Button
            type="text"
            style={{ color: "white" }}
            onClick={() => {
              if (syntheticUsersNumber === 1 || syntheticUsersNumber === undefined) {
                return;
              }

              analytics.track("workspace:number-of-interviews", {
                action: "minus",
                value: syntheticUsersNumber - 1,
              });
              setSyntheticUsersNumber(syntheticUsersNumber - 1);
            }}
          >
            -
          </Button>
          {/* TODO: add validation */}
          <Input
            value={syntheticUsersNumber}
            style={{ width: "40px", textAlign: "center" }}
            onChange={(e) => {
              const { value } = e.target;
              if (value === "" || (value >= "1" && value <= "10")) {
                analytics.track("workspace:number-of-interviews", {
                  action: "number",
                  value: value,
                });
                setSyntheticUsersNumber(parseInt(value));
              } else {
                const value = parseInt((e.target as HTMLInputElement).value);
                if (value >= 1 && value <= 10) {
                  analytics.track("workspace:number-of-interviews", {
                    action: "number",
                    value: value,
                  });
                  setSyntheticUsersNumber(value);
                }
              }
            }}
          />
          <Button
            type="text"
            style={{ color: "white" }}
            onClick={() => {
              if (syntheticUsersNumber === 10 || syntheticUsersNumber === undefined) {
                return;
              }
              analytics.track("workspace:number-of-interviews", {
                action: "plus",
                value: syntheticUsersNumber + 1,
              });
              setSyntheticUsersNumber(syntheticUsersNumber + 1);
            }}
          >
            +
          </Button>
        </Space>
      </div>

      <div>
        <SignupRequiredTrigger>
          <Tooltip
            title={
              disabled
                ? "Please select at least one input in all active columns before generating."
                : ""
            }
          >
            <div style={{ backgroundColor: "white", borderRadius: "6px" }}>
              <Button
                type="primary"
                style={{ height: "58px" }}
                onClick={handleClick}
                disabled={disabled}
              >
                Generate SUs
              </Button>
            </div>
          </Tooltip>
        </SignupRequiredTrigger>
      </div>
    </Space>
  );
};
