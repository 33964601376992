import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Typography, theme } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { WORKSPACE_PLANS_ROUTE } from "templates/workspace-plans/workspace-plans-routes";

export function PlanUpgradeAlert() {
  const { token } = theme.useToken();
  const router = useRouter();
  const analytics = getAnalytics();

  const workspace = useAppSelector((state) => state.workspaces.data.workspace);

  const noActiveSubscription = useMemo(
    () => !!workspace?.activeSubscription && workspace?.activeSubscription !== true,
    [workspace?.activeSubscription]
  );

  const trialSubscription = useMemo(
    () => !!workspace?.trialSubscription && workspace?.trialSubscription === true,
    [workspace?.trialSubscription]
  );

  const interviewsLeft = useMemo(
    () =>
      !!workspace?.subscriptionUsageStats?.interviewsLimit &&
      !!workspace?.subscriptionUsageStats?.interviewsUsage &&
      Math.max(
        0,
        (workspace?.subscriptionUsageStats?.interviewsLimit || 0) -
          (workspace?.subscriptionUsageStats?.interviewsUsage || 0)
      ),
    [workspace?.subscriptionUsageStats]
  );

  const interviewsEndingSoon = useMemo(
    () => !!interviewsLeft && interviewsLeft <= 10 && interviewsLeft > 0,
    [interviewsLeft]
  );

  const subscriptionEndDate = useMemo(
    () =>
      workspace?.subscriptionEndDate
        ? new Date(workspace?.subscriptionEndDate).toLocaleDateString()
        : "",
    [workspace?.subscriptionEndDate]
  );

  const alertProps = useMemo(() => {
    if (noActiveSubscription) {
      return {
        title: "You are not subscribed to any plan.",
        message: "Sign up to one of our plans to keep on generating valuable insights.",
      };
    }

    if (trialSubscription) {
      return {
        title: `Your free trial ends on ${subscriptionEndDate}.`,
        message: "Keep in mind that you'll be automatically charged after your free trial ends.",
      };
    }

    if (interviewsEndingSoon && typeof interviewsLeft === "number") {
      return {
        title: `You have ${interviewsLeft} interviews left.`,
        message: `Your interview balance renews on ${subscriptionEndDate}. Once you run out of interviews, you won't be able to generate insights.`,
      };
    }

    if (interviewsLeft === 0) {
      return {
        title: `You have reached the limit of interviews. You have 0 interviews left.`,
        message: `Your interview balance renews on ${subscriptionEndDate}. Upgrade your plan to keep on generating insights.`,
      };
    }

    return undefined;
  }, [
    noActiveSubscription,
    trialSubscription,
    interviewsEndingSoon,
    interviewsLeft,
    subscriptionEndDate,
  ]);

  return (
    <div
      style={{
        display:
          alertProps && EnvironmentVariables.PAYWALL_ACTIVATION_ENABLED === "true"
            ? "block"
            : "none",
      }}
    >
      <Alert
        style={{
          marginTop: token.padding,
          marginRight: token.paddingLG,
          marginLeft: token.paddingLG,
        }}
        type="warning"
        closable
        onClose={() => {
          analytics.track("workspace:plan-alert:close");
        }}
        message={
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <div
                  style={{ display: "flex", flexDirection: "column", marginLeft: token.marginMD }}
                >
                  <Typography.Text strong>{alertProps?.title}</Typography.Text>
                  <Typography.Text>{alertProps?.message}</Typography.Text>
                </div>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  analytics.track("workspace:plans-alert:view-plans");
                  router.push(WORKSPACE_PLANS_ROUTE.getHref());
                }}
              >
                {"View plans"}
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
}
