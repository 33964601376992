import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React from "react";

type FooterActionProps = {
  triggerIcon: IconProp;
  buttonAction?: () => void;
  options: ItemType[];
};

const FooterAction: React.FC<FooterActionProps> = ({ triggerIcon, buttonAction, options }) => {
  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items: options,
      }}
    >
      <Button
        style={{ backgroundColor: "transparent", borderColor: "transparent" }}
        shape="circle"
        onClick={buttonAction}
      >
        <FontAwesomeIcon icon={triggerIcon} />
      </Button>
    </Dropdown>
  );
};

export default FooterAction;
