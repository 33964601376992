import { Divider, Image, Radio } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { getAnalytics } from "logic/analytics/analytics";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetSelectedProblems } from "store/modules/problems/actions/index";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { resetSelectedSolution } from "store/modules/solutions/actions/index";
import { setProjectSettings } from "store/modules/projects/actions";
import { ProjectSettings } from "store/modules/projects/types";
import custom from "./../images/custom.svg";
import dynamic from "./../images/dynamic.svg";
import problem from "./../images/problem.svg";
import shadow from "./../images/shadow.svg";
import solution from "./../images/solution.svg";
import triangle from "./../images/triangle.svg";
import { InterviewModeButton } from "./interview-mode-button";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { WorkspaceMode } from "store/modules/workspaces/types";

type InterviewModeSelectorProps = {
  workspaceMode: WorkspaceMode;
  projectSettings: ProjectSettings;
  subscriptionActive?: boolean;
  setShowCustomScriptModal: (show: boolean) => void;
  setShowDynamicInterviewResearchGoalModal: (show: boolean) => void;
  setShowPlanUpgradeModal: (show: boolean) => void;
};

export const InterviewModeSelector: React.FC<InterviewModeSelectorProps> = ({
  workspaceMode,
  projectSettings,
  subscriptionActive,
  setShowCustomScriptModal,
  setShowDynamicInterviewResearchGoalModal,
  setShowPlanUpgradeModal,
}) => {
  const dispatch = useAppDispatch();
  const extraToken = useExtraToken();
  const divRef = useRef<HTMLDivElement>(null);
  const analytics = getAnalytics();
  const [isScrollable, setIsScrollable] = useState(false);

  // FEATURE FLAG
  const dynamicInterviewsEnabled = useFeatureFlagEnabled("dynamic-interviews");

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement) {
      setIsScrollable(divElement.scrollWidth > divElement.clientWidth);
    }

    const handleResize = () => {
      if (divElement !== undefined && divElement !== null) {
        setIsScrollable(divElement?.scrollWidth > divElement?.clientWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <style jsx global>
        {`
          /* styles.css */
          .scrollTopBar::-webkit-scrollbar {
            display: none;
          }

          .scrollTopBar {
            scrollbar-width: none; /* Firefox */
          }
        `}
      </style>
      <Radio.Group
        disabled={
          workspaceMode !== WorkspaceMode.Regular && workspaceMode !== WorkspaceMode.AnonymousUser
        }
        value={projectSettings}
      >
        <div
          className="scrollTopBar"
          style={{ display: "flex", overflow: "auto", whiteSpace: "nowrap", paddingBottom: "12px" }}
          ref={divRef}
        >
          {EnvironmentVariables.DYNAMIC_INTERVIEWS_ENABLED === "true" && (
            <InterviewModeButton
              name="Dynamic Interview"
              description="Provide the goal"
              value="dynamic_interview"
              image={(dynamic as { src: string }).src}
              active={projectSettings === ProjectSettings.DynamicInterviewMode}
              onClick={() => {
                dispatch(setProjectSettings(ProjectSettings.DynamicInterviewMode));
                dispatch(resetSelectedProblems());
                dispatch(resetSelectedSolution());
                setShowDynamicInterviewResearchGoalModal(true);
                analytics.track("workspace:study-strategy:selection", {
                  study_stategy: "dynamic_interview",
                });
              }}
              disabled={workspaceMode === WorkspaceMode.SavedStudy || !dynamicInterviewsEnabled}
              tooltipText={
                dynamicInterviewsEnabled
                  ? ""
                  : "Feature currently disabled while we improve stability"
              }
            />
          )}
          <InterviewModeButton
            name="Custom Interview"
            description="You are in control"
            image={(custom as { src: string }).src}
            value="custom_script"
            active={projectSettings === ProjectSettings.CustomScriptMode}
            onClick={() => {
              if (
                EnvironmentVariables.PAYWALL_ACTIVATION_ENABLED === "false" ||
                subscriptionActive
              ) {
                dispatch(setProjectSettings(ProjectSettings.CustomScriptMode));
                dispatch(resetSelectedProblems());
                dispatch(resetSelectedSolution());
                setShowCustomScriptModal(true);
                analytics.track("workspace:study-strategy:selection", {
                  study_stategy: "custom_script",
                });
              } else {
                setShowPlanUpgradeModal(true);
              }
            }}
            disabled={workspaceMode === WorkspaceMode.SavedStudy}
          />
          <div
            style={{
              height: "inherit",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              type="vertical"
              style={{ width: "1px", height: "33px", backgroundColor: "white" }}
            />
          </div>
          <InterviewModeButton
            name="Problem Exploration Interview"
            description=""
            value="problem_exploration"
            image={(problem as { src: string }).src}
            active={projectSettings === ProjectSettings.ProblemExplorationMode}
            onClick={() => {
              dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
              dispatch(resetResearchGoal());
              dispatch(resetCustomScript());
              analytics.track("workspace:study-strategy:selection", {
                study_stategy: "problem_exploration",
              });
            }}
            disabled={workspaceMode === WorkspaceMode.SavedStudy}
          />
          <InterviewModeButton
            name="Solution Feedback Interview"
            description=""
            value="solution_feedback"
            image={(solution as { src: string }).src}
            active={projectSettings === ProjectSettings.SolutionFeedbackMode}
            onClick={() => {
              dispatch(setProjectSettings(ProjectSettings.SolutionFeedbackMode));
              dispatch(resetResearchGoal());
              dispatch(resetCustomScript());
              analytics.track("workspace:study-strategy:selection", {
                study_stategy: "solution_feedback",
              });
            }}
            disabled={workspaceMode === WorkspaceMode.SavedStudy}
          />

          <div style={{ position: "sticky", right: 0, bottom: 0, display: "flex" }}>
            {isScrollable && (
              <div style={{ height: "60px", width: "35px", position: "relative" }}>
                <Image
                  src={(shadow as { src: string }).src}
                  preview={false}
                  alt="Scrollable"
                  style={{ position: "absolute", top: 0, left: 0, width: "38px", height: "60px" }}
                />
                <Image
                  src={(triangle as { src: string }).src}
                  preview={false}
                  alt="Scrollable"
                  style={{
                    position: "absolute",
                    top: "23px",
                    left: "23px",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </div>
            )}
            <div
              style={{
                marginLeft: 0,
                backgroundColor: extraToken.colorSideBarBg,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Divider
                type="vertical"
                style={{
                  width: "1px",
                  height: "33px",
                  backgroundColor: "white",
                  alignContent: "center",
                }}
              />
            </div>
          </div>
        </div>
      </Radio.Group>
    </>
  );
};
