import { WorkspacePageContent } from "./content/workspace-page-content";
import { SignupRequiredAlert } from "./signup-required-alert/signup-required-alert";
import { DrawerContextProvider } from "./contexts/drawer/provider";
import { StudyContainerContextProvider } from "./contexts/insights-panel/provider";



export const WorkspacePage: React.FC = () => {
  /*
    Set up all contexts that are needed for this page.
    Inject preloaded data into state, if coming from a shared workspace URL
  // */
  // const dispatch = useAppDispatch();

  return (
    <StudyContainerContextProvider>
      <DrawerContextProvider>
        <SignupRequiredAlert>
          {/* Page content is rendered here*/}
          <WorkspacePageContent />
        </SignupRequiredAlert>
      </DrawerContextProvider>
    </StudyContainerContextProvider>
  );
};
