import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const CUSTOM_SCRIPT_KEY = "workspace-page:custom-script";

export function useCustomScriptInput() {
  const localStorage = useLocalStorage();

  const customScriptInput = useMemo(() => {
    return localStorage.getItem(z.string().optional(), CUSTOM_SCRIPT_KEY);
  }, [localStorage]);

  const saveCustomScriptInput = useCallback(
    (customScript: string) => {
      localStorage.setItem(CUSTOM_SCRIPT_KEY, customScript);
    },
    [localStorage]
  );

  const removeCustomScriptInput = useCallback(() => {
    return localStorage.removeItem(CUSTOM_SCRIPT_KEY);
  }, [localStorage]);

  return useMemo(
    () => ({
      customScriptInput,
      saveCustomScriptInput,
      removeCustomScriptInput,
    }),
    [customScriptInput, saveCustomScriptInput, removeCustomScriptInput]
  );
}
