import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addProblem,
  deleteAllProblems,
  deleteProblem,
  toggleSelectedProblems,
  updateProblem,
} from "store/modules/problems/actions/index";
import { NewWorkspaceBoardSection } from "../shared/section";

import {
  faClone,
  faEllipsis,
  faMagicWandSparkles,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAnalytics } from "logic/analytics/analytics";
import { useMemo, useState } from "react";
import { inferAudiencesByProblem } from "store/modules/audiences/actions/index";
import { ProjectSettings } from "store/modules/projects/types";
import { LoadingStatus } from "store/types";
import { resetInsightsData } from "store/utils/reset-insights-data";
import FooterAction from "../shared/section/components/footer-action";
import { WorkspaceMode } from "store/modules/workspaces/types";

export const PROBLEMS_SECTION_ID = "problems-section";

export enum DeleteModal {
  single = "single",
  all = "all",
}

export function Problems() {
  const dispatch = useAppDispatch();
  const problems = useAppSelector((state) => state.problems);
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
  const project = useAppSelector((state) => state.projects.data);
  const projectError = useAppSelector((state) => state.projects.error);
  const analytics = getAnalytics();
  const [showDeleteModal, setShowDeleteModal] = useState<"all" | string | undefined>(undefined);

  const isDisabled = useMemo(
    () =>
      workspaceMode === WorkspaceMode.SavedStudy ||
      (project.projectSettings &&
        [ProjectSettings.DynamicInterviewMode, ProjectSettings.CustomScriptMode].includes(
          project.projectSettings
        )),
    [project.projectSettings, workspaceMode]
  );

  const isLoading: LoadingStatus = useMemo(() => {
    if (problems.error || projectError) return LoadingStatus.none;
    return !problems.data.problems ? LoadingStatus.multiple : problems.loading;
  }, [problems.data.problems, problems.loading, problems.error, projectError]);

  return (
    <NewWorkspaceBoardSection
      htmlId={PROBLEMS_SECTION_ID}
      resourceName="problems"
      resources={problems.data.problems || []}
      selectionMode="multiple"
      selectedIds={problems.data.selectedProblemsIds || []}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      disabled={isDisabled}
      onSelect={(resource) => {
        dispatch(toggleSelectedProblems(resource.id));
        resetInsightsData();
      }}
      onAdd={(resource) => {
        analytics.track("workspace:problem:add");
        dispatch(
          addProblem({
            projectId: project.project?.id || "",
            description: resource.description,
          })
        );
        resetInsightsData();
      }}
      onUpdate={(resource) => {
        analytics.track("workspace:problem:card:edit");
        dispatch(
          updateProblem({
            projectId: project.project?.id || "",
            problemId: resource.id,
            description: resource.description,
          })
        );
        resetInsightsData();
      }}
      onRemove={(resource) => {
        analytics.track("workspace:problem:card:remove");
        dispatch(deleteProblem({ problemId: resource.id }));
        resetInsightsData();
      }}
      onRemoveAll={() => {
        analytics.track("workspace:problem:delete-all");
        dispatch(deleteAllProblems());
        resetInsightsData();
      }}
      loading={isLoading}
      error={problems.error || projectError}
      itemFooterActions={(resource) => [
        <FooterAction
          key={`magic-wand-actions-${resource.id}`}
          triggerIcon={faMagicWandSparkles}
          buttonAction={() => {
            analytics.track("workspace:problem:card:magic-wand");
          }}
          options={[
            {
              key: "infer-audiences-by-problem",
              label: "Suggest audiences",
              icon: <FontAwesomeIcon icon={faUser} />,
              onClick: () => {
                dispatch(inferAudiencesByProblem({ problemId: resource.id }));
                resetInsightsData();
                analytics.track("workspace:problem:card:magic-wand:suggest-audiences", {
                  problem_id: resource.id,
                });
              },
            },
          ]}
        />,
        <FooterAction
          key={`options-${resource.id}`}
          triggerIcon={faEllipsis}
          options={[
            {
              key: "edit",
              label: "Duplicate",
              icon: <FontAwesomeIcon icon={faClone} />,
              onClick: () => {
                analytics.track("workspace:problem:card:ellipsis:duplicate");
                dispatch(
                  addProblem({
                    projectId: project.project?.id || "",
                    description: resource.description,
                  })
                );
                resetInsightsData();
              },
            },
            {
              key: "delete",
              label: "Delete",
              danger: true,
              icon: <FontAwesomeIcon icon={faTrash} />,
              onClick: () => {
                analytics.track("workspace:problem:card:ellipsis:remove");
                setShowDeleteModal(resource.id);
              },
            },
          ]}
        />,
      ]}
    />
  );
}
