import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";

import { INSIGHTS_PANEL_SCROLLABLE_CONTAINER_ID } from "../workspace-page-constants";
import { StudyContainerTopBar } from "../study-container/top-bar";
import { useStudyContainerContext } from "../../contexts/insights-panel";
import { StudyContainerComponent } from "../study-container";
import { useDrawerContext } from "../../contexts/drawer";

type DrawerContainerProps = {
  contentWidth?: number;
};

export const DrawerContainer: React.FC<DrawerContainerProps> = ({ contentWidth }) => {
  const token = useToken();

  const analytics = getAnalytics();
  const syntheticUsersData = useAppSelector((state) => state.syntheticUsers.data);

  const { studyContainerTab, resetStudyContainerContext } = useStudyContainerContext();
  const { drawerOpen, setDrawerOpen } = useDrawerContext();

  const onCloseInsightsPanel = () => {
    analytics.track("workspace:insights:panel:close");
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (!syntheticUsersData) {
      // reset workspace page context when synthetic users are not available
      resetStudyContainerContext();
    }
  }, [syntheticUsersData, resetStudyContainerContext]);

  return (
    <Drawer
      forceRender={true}
      styles={{ header: { padding: 0, margin: 0 }, body: { padding: 0 } }}
      title={
        <StudyContainerTopBar
          studyContainerTab={studyContainerTab}
          onCloseDrawer={onCloseInsightsPanel}
          isInsideDrawer={true}
        />
      }
      placement={"right"}
      closable={false}
      onClose={onCloseInsightsPanel}
      open={drawerOpen}
      width={contentWidth}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            height: "calc(100vh - 80px)",
            position: "sticky",
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: token.paddingSM,
            paddingLeft: token.paddingSM,
            backgroundColor: "#F6F6FF",
          }}
        >
          <Button
            shape="circle"
            onClick={() => {
              analytics.track("workspace:study:close-arrow");
              onCloseInsightsPanel();
            }}
            icon={<AntIcon component={() => <FontAwesomeIcon icon={faChevronRight} />} />}
          />
        </div>
        <div
          id={INSIGHTS_PANEL_SCROLLABLE_CONTAINER_ID}
          style={{
            flex: "1 1 auto",
            minHeight: 0,
            paddingRight: token.padding,
            backgroundColor: "#F6F6FF",
          }}
        >
          <StudyContainerComponent />
        </div>
      </div>
    </Drawer>
  );
};
