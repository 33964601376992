import { Button, Input, Modal, Space, Typography } from "antd";
import { useResearchGoalInput } from "logic/hooks/research-goal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getAnalytics } from "logic/analytics/analytics";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { addResearchGoal } from "store/modules/research-goal/actions";

type ResearchGoalModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const ResearchGoalModal: React.FC<ResearchGoalModalProps> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();
  const researchGoal = useAppSelector((state) => state.researchGoal);
  const projects = useAppSelector((state) => state.projects);

  const localStorageResearchGoalInput = useResearchGoalInput();

  // Current research goal
  const [value, setValue] = useState(localStorageResearchGoalInput.researchGoalInput || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const analytics = getAnalytics();

  // textarea ref
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSave = useCallback(() => {
    if (!projects.data.project?.id || !value.length) {
      throw new Error("Workspace is undefined");
    }

    dispatch(addResearchGoal({ projectId: projects.data.project?.id, description: value }));
    setIsSubmitting(true);
    localStorageResearchGoalInput.saveResearchGoalInput(value);
  }, [dispatch, projects.data.project?.id, value, localStorageResearchGoalInput]);

  const handleCancel = useCallback(() => {
    setValue(localStorageResearchGoalInput.researchGoalInput || "");
    setVisible(false);
  }, [setValue, setVisible, localStorageResearchGoalInput]);

  const handleClear = useCallback(() => {
    analytics.track("workspace:study-strategy:research-goal-modal:clear");
    setValue("");
    localStorageResearchGoalInput.removeResearchGoalInput();
  }, [setValue, localStorageResearchGoalInput, analytics]);

  // handle submit loading + close modal
  useEffect(() => {
    if (isSubmitting && !researchGoal.loading && !!researchGoal.data?.description) {
      setIsSubmitting(false);
      setVisible(false);
    }
  }, [researchGoal, isSubmitting, setVisible]);

  return (
    <Modal
      title={"Research Goal"}
      open={visible}
      afterOpenChange={(open) => open && textareaRef.current?.focus({})}
      width={"40%"}
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
        analytics.track("workspace:study-strategy:research-goal-modal:close");
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleCancel();
            analytics.track("workspace:study-strategy:research-goal-modal:cancel");
          }}
        >
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={() => {
            handleSave();
            analytics.track("workspace:study-strategy:research-goal-modal:save");
          }}
          disabled={!value?.length}
          loading={isSubmitting}
        >
          Save
        </Button>,
      ]}
    >
      <Space size="large" align="start">
        <Typography.Paragraph>
          The research goal is the main question you want to answer with your research. It should be
          a single sentence that is as specific as possible.
        </Typography.Paragraph>
        <Button onClick={handleClear}>Clear</Button>
      </Space>
      <Input.TextArea
        className="custom-script-text-area"
        ref={textareaRef}
        autoSize={{ minRows: 3, maxRows: 4 }}
        value={value}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        style={{
          resize: "none",
          width: "100%",
        }}
        onChange={(e) => setValue(e.target.value)}
      ></Input.TextArea>
    </Modal>
  );
};
