import { ReactNode, useState } from "react";
import { DrawerContext } from ".";

type WorkspacePageContextProviderProps = {
  children: ReactNode;
};

export const DrawerContextProvider: React.FC<WorkspacePageContextProviderProps> = ({
  children,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <DrawerContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
