import { Row, Space } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useToken } from "components/ui-kit/core/token";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { SignupRequiredTrigger } from "../../signup-required-alert/signup-required-trigger";
import { CustomScriptModal } from "../custom-script-modal";
import { PlanUpgradeModal } from "../plan-upgrade-modal/plan-upgrade-modal";
import { ResearchGoalModal } from "../research-goal-modal";
import { EXPLORATION_MODE_TOGGLE } from "../workspace-page-constants";
import { GenerateSUButton } from "./components/generate-su-button";
import { GenerateSUButtonCollapsed } from "./components/generate-su-button-collapsed";
import { InterviewModeSelector } from "./components/interview-mode-selector";
import { InterviewModeSelectorCollapsed } from "./components/interview-mode-selector-collapsed";

export const GENERATE_INTERVIEWS_ID = "generate-interviews";

type TopBarProps = {
  onGenerateButtonClick: (syntheticUsersNumber: number) => void;
};

export const TopBar: React.FC<TopBarProps> = ({ onGenerateButtonClick }) => {
  const token = useToken();
  const extraToken = useExtraToken();

  const workspace = useAppSelector((state) => state.workspaces.data);
  const project = useAppSelector((state) => state.projects.data);

  // Modal handlers
  const [showCustomScriptModal, setShowCustomScriptModal] = useState(false);
  const [showDynamicInterviewResearchGoalModal, setShowDynamicInterviewResearchGoalModal] =
    useState(false);
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [syntheticUsersNumber, setSyntheticUsersNumber] = useState(3);

  const planUpgradeError = useMemo(
    () =>
      workspace?.workspace?.subscriptionId
        ? TransportFailure.PlanUpgradeNeeded
        : TransportFailure.SubscriptionNotFound,
    [workspace?.workspace?.subscriptionId]
  );

  const isCollapsed = useMemo(() => windowWidth < 1300, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!workspace.workspaceMode || !project.projectSettings) return null;

  return (
    <div style={{ borderLeft: "1px solid #595959" }}>
      <div
        style={{
          background: extraToken.colorSideBarBg,
          paddingTop: isCollapsed ? "26px" : "12px",
          paddingBottom: isCollapsed ? "26px" : "0px",
          paddingLeft: token.paddingLG,
          paddingRight: token.paddingLG,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <CustomScriptModal visible={showCustomScriptModal} setVisible={setShowCustomScriptModal} />
        <ResearchGoalModal
          visible={showDynamicInterviewResearchGoalModal}
          setVisible={setShowDynamicInterviewResearchGoalModal}
        />
        <PlanUpgradeModal
          error={planUpgradeError}
          visible={showPlanUpgradeModal}
          setVisible={setShowPlanUpgradeModal}
        />

        {isCollapsed ? (
          <Row align={"middle"} gutter={token.margin}>
            <Space direction="horizontal">
              <SignupRequiredTrigger>
                <div id={EXPLORATION_MODE_TOGGLE}>
                  <InterviewModeSelectorCollapsed
                    workspaceMode={workspace.workspaceMode}
                    projectSettings={project.projectSettings}
                    subscriptionActive={workspace.workspace?.activeSubscription}
                    setShowCustomScriptModal={setShowCustomScriptModal}
                    setShowDynamicInterviewResearchGoalModal={
                      setShowDynamicInterviewResearchGoalModal
                    }
                    setShowPlanUpgradeModal={setShowPlanUpgradeModal}
                  />
                </div>
              </SignupRequiredTrigger>

              <div id={GENERATE_INTERVIEWS_ID} style={{ marginLeft: token.marginXL }}>
                <GenerateSUButton
                  onSubmit={() => onGenerateButtonClick(syntheticUsersNumber)}
                  syntheticUsersNumber={syntheticUsersNumber}
                  setSyntheticUsersNumber={setSyntheticUsersNumber}
                  workspaceMode={workspace.workspaceMode}
                />
              </div>
            </Space>
          </Row>
        ) : (
          <div style={{ width: "100%" }}>
            <Space
              direction="horizontal"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <SignupRequiredTrigger>
                <div id={EXPLORATION_MODE_TOGGLE}>
                  <InterviewModeSelector
                    workspaceMode={workspace.workspaceMode}
                    projectSettings={project.projectSettings}
                    subscriptionActive={workspace.workspace?.activeSubscription}
                    setShowCustomScriptModal={setShowCustomScriptModal}
                    setShowDynamicInterviewResearchGoalModal={
                      setShowDynamicInterviewResearchGoalModal
                    }
                    setShowPlanUpgradeModal={setShowPlanUpgradeModal}
                  />
                </div>
              </SignupRequiredTrigger>
              <div id={GENERATE_INTERVIEWS_ID}>
                <GenerateSUButtonCollapsed
                  onSubmit={() => onGenerateButtonClick(syntheticUsersNumber)}
                  syntheticUsersNumber={syntheticUsersNumber}
                  setSyntheticUsersNumber={setSyntheticUsersNumber}
                  workspaceMode={workspace.workspaceMode}
                />
              </div>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};
