import { Card, Checkbox, Modal, Radio, Spin, Tooltip, theme } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { formatStringToSingular } from "logic/internals/utils/format-string-to-singular";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useAppSelector } from "store/hooks";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";
import tinycolor from "tinycolor2";
import { WorkspaceBoardResource } from "..";
import { WorkspaceMode } from "store/modules/workspaces/types";

type SectionBaseItemProps = {
  resourceName: string;
  resourceLoading: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  onSubmit: (description: string) => void;
  isAdding: boolean;
};

type SectionItemAddProps = SectionBaseItemProps & {
  isAdding: true;
  setIsAdding?: Dispatch<SetStateAction<boolean>>;
  resource?: never;
  role?: never;
  onSelect?: never;
  onRemove?: never;
  footerActions?: never;
  selected?: never;
  disabled?: never;
  disabledText?: never;
  showDeleteModal?: never;
  setShowDeleteModal?: never;
};

type SectionItemExistingProps = SectionBaseItemProps & {
  isAdding: false;
  setIsAdding?: never;
  resource: WorkspaceBoardResource;
  role?: "radio" | "checkbox";
  onSelect: () => void;
  onRemove: () => void;
  footerActions: React.ReactNode[];
  selected?: boolean;
  disabled?: boolean;
  disabledText?: string;
  showDeleteModal?: string;
  setShowDeleteModal: Dispatch<SetStateAction<string | undefined>>;
};

type SectionItemProps = SectionItemAddProps | SectionItemExistingProps;

export const SectionItem: React.FC<SectionItemProps> = ({
  resourceName,
  resource,
  resourceLoading,
  containerRef,
  role = "radio",
  onSubmit,
  onSelect,
  onRemove,
  footerActions,
  isAdding,
  setIsAdding,
  selected,
  disabled,
  disabledText,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);

  const { token } = theme.useToken();
  const extraToken = useExtraToken();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const readOnly = workspaceMode !== WorkspaceMode.Regular || disabled;

  const [value, setValue] = useState(resource?.description);
  const [submitting, setSubmitting] = useState(false);

  const scrollToContainerBottom = useCallback(() => {
    containerRef.current &&
      containerRef.current.scrollBy({ top: containerRef.current.scrollHeight });
  }, [containerRef]);

  useEffect(() => {
    if (isAdding) {
      scrollToContainerBottom();
      textareaRef.current && textareaRef.current.focus({ preventScroll: true });
    }
  }, [isAdding, scrollToContainerBottom]);

  useEffect(() => {
    if (submitting && !resourceLoading) {
      setIsAdding?.(false);
      setSubmitting(false);

      if (isAdding) {
        scrollToContainerBottom();
      }
    }
  }, [isAdding, resourceLoading, submitting, setIsAdding, scrollToContainerBottom]);

  const handleSubmit = () => {
    if (!value || !value.trim()) {
      setIsAdding?.(false);
    } else if (value && resource?.description !== value) {
      onSubmit(value);
      setSubmitting(true);
    }
  };

  return (
    <>
      <style jsx global>{`
        .message-text-area {
          width: 100%;
          background-color: transparent;

          border: none;
          outline: none;

          border: 1px solid transparent;

          border-radius: ${token.borderRadius}px;
        }

        .message-text-area.editable:hover {
          background-color: ${token.colorFillSecondary};
        }

        .message-text-area.editable:focus,
        .message-text-area.editable:active {
          background-color: ${token.colorBgBase};
          border: 1px solid ${token.colorPrimaryText};
        }

        .message-text-area.editable.selected:hover {
          background-color: ${extraToken.colorPrimaryBgFillSecondary};
        }

        .message-text-area.editable.selected:focus,
        .message-text-area.editable.selected:active {
          background-color: ${token.colorBgBase};
          border: 1px solid ${token.colorPrimaryText};
        }
      `}</style>

      {!isAdding && (
        <Modal
          title={`Delete this ${formatStringToSingular(resourceName)}?`}
          open={showDeleteModal === resource?.id}
          okText="Delete"
          okButtonProps={{ danger: true }}
          onOk={() => {
            setShowDeleteModal(undefined);
            setSubmitting(true);
            onRemove();
          }}
          onCancel={() => setShowDeleteModal(undefined)}
        >
          <p>This information will be lost</p>
        </Modal>
      )}

      <Spin spinning={submitting}>
        <Card
          role={role}
          onClick={() => !readOnly && !isAdding && onSelect()}
          styles={{ body: { padding: 0 } }}
          style={{
            background: selected
              ? tinycolor(token.colorPrimaryBg).saturate(10).toRgbString()
              : disabled
                ? "transparent"
                : token.colorBgElevated,
            border: selected
              ? `1px solid ${tinycolor(token.colorPrimary).setAlpha(0.5).toRgbString()}`
              : undefined,
            boxShadow: token.boxShadow,
            marginBottom: token.marginSM,
          }}
        >
          <>
            <div
              style={{
                padding: token.paddingXS,
                paddingTop: token.paddingXS,
                paddingBottom: 0,
              }}
            >
              {/* Header */}
              {!isAdding && (
                <div
                  style={{
                    paddingBottom: token.paddingSM,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {role === "checkbox" ? (
                    <Tooltip title={disabledText}>
                      <Checkbox
                        onClick={(e) => e.preventDefault()}
                        disabled={readOnly}
                        style={{ marginRight: 0 }}
                        checked={selected}
                      />
                    </Tooltip>
                  ) : (
                    <Radio
                      onClick={(e) => e.preventDefault()}
                      disabled={readOnly || disabled}
                      style={{ marginRight: 0 }}
                      checked={selected}
                    />
                  )}
                </div>
              )}

              {/* Body */}
              <TextareaAutosize
                ref={textareaRef}
                readOnly={readOnly}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setValue(e.target.value)}
                className={`message-text-area ${readOnly ? "" : "editable"} ${
                  selected ? "selected" : ""
                }`}
                value={value}
                onBlur={handleSubmit}
                onKeyDown={(e) => {
                  if (!(e.code == "Enter" && (e.metaKey || e.ctrlKey))) return;
                  handleSubmit();
                }}
                style={{
                  resize: "none",
                  color: disabled ? token.colorTextSecondary : undefined,
                }}
              />
            </div>

            {/* Footer */}
            {!isAdding && (
              <div
                style={{
                  visibility: readOnly ? "hidden" : undefined,
                  paddingLeft: token.paddingXS,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <SignupRequiredTrigger>
                    {/* {itemFooterActions} */}
                    {footerActions}
                  </SignupRequiredTrigger>
                </div>
              </div>
            )}
          </>
        </Card>
      </Spin>
    </>
  );
};
