import { ProjectSettings } from "store/modules/projects/types";

export const getProjectSettingsLabel = (setting: ProjectSettings) => {
  switch (setting) {
    case ProjectSettings.ProblemExplorationMode:
      return "Problem Exploration";
    case ProjectSettings.SolutionFeedbackMode:
      return "Solution Feedback";
    case ProjectSettings.DynamicInterviewMode:
      return "Dynamic Interview";
    case ProjectSettings.CustomScriptMode:
      return "Custom Script";
    default:
      return "Select Interview Type";
  }
};
