import { Col, Row, Typography, theme } from "antd";
import { Problems } from "./problems";
import { Solutions } from "./solutions";
import { Audiences } from "./audiences";
import { BOARD_ID } from "./workspace-board.constants";

export function WorkspaceBoard() {
  const { token } = theme.useToken();

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title
          level={4}
          style={{ margin: 0, marginTop: token.marginXS, marginBottom: token.margin }}
        >
          Your study
        </Typography.Title>
        {/* <div>
          <WalkthroughAndExamples />
        </div> */}
      </div>
      <Row id={BOARD_ID} style={{ flex: "1 1 auto" }} gutter={token.padding}>
        <Col span={8}>
          <Audiences />
        </Col>
        <Col span={8}>
          <Problems />
        </Col>
        <Col span={8}>
          <Solutions />
        </Col>
      </Row>
    </div>
  );
}
