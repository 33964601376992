import { createSelector } from "@reduxjs/toolkit";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { RootState } from "store";
import { Problem } from "store/modules/problems/types";
import { Solution } from "store/modules/solutions/types";
import { ProjectSettings } from "store/modules/projects/types";
import { WorkspaceMode } from "store/modules/workspaces/types";

const isValidCustomScript = (text: string, maxQuestions?: number): boolean => {
  if (text === "") {
    return false;
  }
  if (EnvironmentVariables.PAYWALL_ACTIVATION_ENABLED === "true") {
    const questionCount = text?.split("\n").length;
    return questionCount <= (maxQuestions || 0);
  }
  return true;
};

export const getAllSelected = createSelector(
  (state: RootState) => state,
  (state) => {
    const { workspaces, projects, audiences, problems, solutions, customScript, researchGoal } =
      state;

    const maxQuestions = workspaces.data.workspace?.subscriptionUsageStats?.customScriptMaxQuestions;

    const selectedAudiences: Solution[] = [];
    const selectedProblems: Problem[] = [];
    const selectedSolution: Solution | undefined = solutions.data.solutions?.find((solution) =>
      solutions.data.selectedSolutionId?.includes(solution.id)
    );

    audiences.data.selectedAudiencesIds?.forEach((selectedAudienceId) => {
      const selectedAudience = audiences.data.audiences?.find(
        (audience) => audience.id === selectedAudienceId
      );

      selectedAudience && selectedAudiences.push(selectedAudience);
    });

    problems.data.selectedProblemsIds?.forEach((selectedProblemId) => {
      const selectedProblem = problems.data.problems?.find(
        (problem) => problem.id === selectedProblemId
      );

      selectedProblem && selectedProblems.push(selectedProblem);
    });

    const validAudiences =
      !!audiences.data.selectedAudiencesIds?.length &&
      audiences.data.selectedAudiencesIds.length <= 2;
    const validProblems = !!problems.data.selectedProblemsIds?.length;
    const validSolutions = !!solutions.data.selectedSolutionId?.length;
    const validResearchGoal = !!researchGoal.data;
    const validCustomScript =
      !!customScript.data && isValidCustomScript(customScript.data?.customScript, maxQuestions);

    const isSolutionFeedbackMode: boolean =
      projects.data.projectSettings === ProjectSettings.SolutionFeedbackMode &&
      validAudiences &&
      validProblems &&
      validSolutions;

    const isProblemExplorationMode: boolean =
      projects.data.projectSettings === ProjectSettings.ProblemExplorationMode &&
      validAudiences &&
      validProblems &&
      !validSolutions;

    const isDynamicInterviewMode: boolean =
      projects.data.projectSettings === ProjectSettings.DynamicInterviewMode &&
      validAudiences &&
      validResearchGoal;

    const isCustomScriptMode: boolean =
      projects.data.projectSettings === ProjectSettings.CustomScriptMode &&
      validAudiences &&
      !validProblems &&
      !validSolutions &&
      validCustomScript;

    const isSharedUrlMode: boolean =
      workspaces.data.workspaceMode === WorkspaceMode.SharedUrl ||
      workspaces.data.workspaceMode === WorkspaceMode.SavedStudy;

    const allSelected =
      isProblemExplorationMode ||
      isSolutionFeedbackMode ||
      isDynamicInterviewMode ||
      isCustomScriptMode ||
      isSharedUrlMode
        ? {
            audiences: selectedAudiences,
            problems: selectedProblems,
            solution: selectedSolution,
            customScript: customScript.data,
            researchGoal: researchGoal.data,
          }
        : null;

    return allSelected;
  }
);
