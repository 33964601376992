import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const RESEARCH_GOAL_KEY = "workspace-page:research-goal";

export function useResearchGoalInput() {
  const localStorage = useLocalStorage();

  const researchGoalInput = useMemo(
    () => localStorage.getItem(z.string().optional(), RESEARCH_GOAL_KEY),
    [localStorage]
  );

  const saveResearchGoalInput = useCallback(
    (researchGoal: string) => {
      localStorage.setItem(RESEARCH_GOAL_KEY, researchGoal);
    },
    [localStorage]
  );

  const removeResearchGoalInput = useCallback(() => {
    return localStorage.removeItem(RESEARCH_GOAL_KEY);
  }, [localStorage]);

  return useMemo(
    () => ({
      researchGoalInput,
      saveResearchGoalInput,
      removeResearchGoalInput,
    }),
    [researchGoalInput, saveResearchGoalInput, removeResearchGoalInput]
  );
}
