import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Typography, theme } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { useGetText } from "logic/internals/i18n/get-text";
import { useRouter } from "next/router";
import { WORKSPACE_SIGNUP_ROUTE } from "templates/workspace-settings/workspace-settings.routes";

export function PendingInvitesAlert() {
  const { token } = theme.useToken();
  const router = useRouter();
  const getText = useGetText();
  const analytics = getAnalytics();
  return (
    <Alert
      style={{
        marginTop: token.padding,
        marginRight: token.paddingLG,
        marginLeft: token.paddingLG,
      }}
      closable
      type="info"
      onClose={() => {
        analytics.track("workspace:invitations-alert:close");
      }}
      message={
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <div style={{ display: "flex", flexDirection: "column", marginLeft: token.marginMD }}>
                <Typography.Text strong>
                  {getText("page.workspace.alert.pendinginvites.title")}
                </Typography.Text>
              </div>
            </div>
            <Button
              type="default"
              onClick={() => {
                analytics.track("workspace:invitations-alert:view-invites");
                router.push(`${WORKSPACE_SIGNUP_ROUTE.getHref()}`);
              }}
            >
              {getText("page.workspace.alert.pendinginvites.button")}
            </Button>
          </div>
        </>
      }
    />
  );
}
