import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, theme, Tooltip, Typography } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { getAnalytics } from "logic/analytics/analytics";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { getAllSelected } from "store/selectors/all-selected";
import { SignupRequiredTrigger } from "templates/index/workspace-page/signup-required-alert/signup-required-trigger";

type GenerateSUButtonProps = {
  onSubmit: () => void;
  syntheticUsersNumber: number;
  setSyntheticUsersNumber: (number: number) => void;
  workspaceMode: WorkspaceMode;
};

export const GenerateSUButton: React.FC<GenerateSUButtonProps> = ({
  onSubmit,
  syntheticUsersNumber,
  setSyntheticUsersNumber,
  workspaceMode,
}) => {
  const allSelected = useAppSelector((state) => getAllSelected(state));
  const analytics = getAnalytics();
  const { token } = theme.useToken();
  const extraToken = useExtraToken();

  const disabled = useMemo(
    () =>
      !allSelected ||
      workspaceMode === WorkspaceMode.SharedUrl ||
      workspaceMode === WorkspaceMode.SavedStudy,
    [allSelected, workspaceMode]
  );

  const handleClick = useDebouncedCallback(() => {
    onSubmit();
  });

  return (
    <Space direction="horizontal" align="center">
      <div>
        <Typography.Text style={{ color: "white" }}>{"Number of interviews:"}</Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Dropdown
            disabled={
              workspaceMode !== WorkspaceMode.Regular &&
              workspaceMode !== WorkspaceMode.AnonymousUser
            }
            placement="bottomRight"
            menu={{
              items: Array.from({ length: 10 }, (_, i) => {
                const key = i + 1;
                return {
                  key,
                  label: key,
                  style: {
                    color: "white",
                    backgroundColor:
                      syntheticUsersNumber === key ? token.colorPrimary : extraToken.colorSideBarBg,
                  },
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    analytics.track("workspace:number-of-interviews", {
                      action: "number",
                      value: key,
                    });
                    setSyntheticUsersNumber(key);
                  },
                };
              }),
              selectable: true,
              style: { backgroundColor: extraToken.colorSideBarBg },
            }}
          >
            <Button value="template" style={{}}>
              <Space>
                {syntheticUsersNumber}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div>
          <SignupRequiredTrigger>
            <Tooltip
              title={
                disabled
                  ? "Please select at least one input in all active columns before generating."
                  : ""
              }
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "6px",
                  marginLeft: token.margin,
                }}
              >
                <Button
                  id="generate-insights-button"
                  type="primary"
                  onClick={handleClick}
                  disabled={disabled}
                >
                  Generate SUs
                </Button>
              </div>
            </Tooltip>
          </SignupRequiredTrigger>
        </div>
      </div>
    </Space>
  );
};
